import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        {
          staticClass: "investor-dashboard-container",
          attrs: { id: "dashboard", fluid: "", tag: "section" },
        },
        [
          _c("SignContractBanner"),
          _c("div", [
            _c(
              "h4",
              {
                staticClass: "h5 mt-3 dark-color",
                attrs: {
                  "data-test": "Dashboard:InvestorDashboard:TitleInvestments",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$tc("my_investments")) + " ")]
            ),
            !_vm.investorHaveInvestments
              ? _c("span", { staticClass: "dark-color" }, [
                  _vm._v(" " + _vm._s(_vm.$t("no_investments")) + " "),
                ])
              : _vm._e(),
            _vm.investments.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "h5",
                      { staticClass: "investment-sub-title dark-color" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("investment_sub_title")) + " "
                        ),
                      ]
                    ),
                    _c("CardFormattedCurrency", {
                      attrs: {
                        cardTitle: _vm.$t("invested"),
                        totalInvested: _vm.totalInvested,
                      },
                    }),
                    _vm.enInvestorReportRedirect === "TRUE"
                      ? _c(
                          VBtn,
                          {
                            staticClass: "mb-8",
                            attrs: { color: "primary" },
                            on: {
                              click: function () {
                                return _vm.handleRedirectInvestorApp()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("investment_report")))]
                        )
                      : _vm._e(),
                    _c(VSimpleTable, { staticClass: "mt-3" }, [
                      _c(
                        "thead",
                        {
                          staticClass: "text-center",
                          attrs: { id: "investor-table-header" },
                        },
                        [
                          _c(
                            "tr",
                            _vm._l(_vm.headers_investments, function (item, i) {
                              return _c(
                                "th",
                                {
                                  key: i,
                                  staticClass:
                                    "text-center py-4 investment-table-header-item",
                                },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "tbody",
                        { staticStyle: { "background-color": "var(--white)" } },
                        _vm._l(_vm.investments, function (item, i) {
                          return _c(
                            "tr",
                            { key: i, staticClass: "investor-table-line" },
                            [
                              _c("td", { staticClass: "dark-color" }, [
                                _vm._v(" " + _vm._s(item.companyName) + " "),
                              ]),
                              _c(
                                "td",
                                { staticClass: "dark-color" },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "investment-table-details",
                                    attrs: {
                                      icon: "fa-solid fa-chart-column",
                                      "data-test":
                                        "Dashboard:InvestorDashboard:InvestmentDetails",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.company_details(
                                          item.companyId
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("td", { staticClass: "dark-color" }, [
                                _vm._v(" " + _vm._s(item.stockQuantity) + " "),
                              ]),
                              _c("td", { staticClass: "dark-color" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatToCurrency(
                                        item.currency,
                                        item.totalInvested
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "dark-color" }, [
                                _vm._v(" " + _vm._s(item.veihcleName) + " "),
                              ]),
                              _c("td", { staticClass: "dark-color" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatToCurrency(
                                        item.currency,
                                        item.currentPosition
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "dark-color" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatToCurrency(
                                        item.currency,
                                        item.earnings
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "dark-color" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatToCurrency(
                                        item.currency,
                                        item.totalPosition
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "dark-color" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.get_date_string(item.date)) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", [
            _c(
              "h4",
              {
                staticClass: "h5 mt-3 dark-color",
                attrs: { id: "allocations" },
              },
              [_vm._v(" " + _vm._s(_vm.$t("allocation_history")) + " ")]
            ),
            !_vm.investorHaveAllocations
              ? _c("span", { staticClass: "dark-color" }, [
                  _vm._v(" " + _vm._s(_vm.$t("no_allocations")) + " "),
                ])
              : _vm._e(),
            _vm.allocations.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "h5",
                      { staticClass: "allocations-sub-title dark-color" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("company_interest_sub_title")) +
                            " "
                        ),
                      ]
                    ),
                    _c(VSimpleTable, { staticClass: "mt-3" }, [
                      _c(
                        "thead",
                        {
                          staticClass: "text-center",
                          attrs: { id: "investor-table-header" },
                        },
                        [
                          _c(
                            "tr",
                            _vm._l(_vm.headers_allocations, function (item, i) {
                              return _c(
                                "th",
                                { key: i, staticClass: "py-4 text-center" },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "tbody",
                        { staticStyle: { "background-color": "var(--white)" } },
                        _vm._l(_vm.allocations, function (item, i) {
                          return _c(
                            "tr",
                            {
                              key: i,
                              staticClass: "investor-table-line",
                              attrs: {
                                "data-test":
                                  "Dashboard:InvestorDashboard:AllocationRow",
                              },
                            },
                            [
                              _c("td", { staticClass: "dark-color" }, [
                                _vm._v(" " + _vm._s(item.company.name) + " "),
                              ]),
                              _c(
                                "td",
                                {
                                  staticClass: "dark-color",
                                  attrs: {
                                    "data-test":
                                      "Dashboard:InvestorDashboard:Value",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatToCurrency(
                                          item.currency,
                                          item.value
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              item.investmentVehicle.name
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "dark-color",
                                      attrs: {
                                        "data-test":
                                          "Dashboard:InvestorDashboard:Vehicle",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.investmentVehicle.name) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "td",
                                    {
                                      staticClass: "dark-color",
                                      attrs: {
                                        "data-test":
                                          "Dashboard:InvestorDashboard:Vehicle",
                                      },
                                    },
                                    [_vm._v(" - ")]
                                  ),
                              _c("td", { staticClass: "dark-color" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.get_date_string(item.timestamp)
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "td",
                                {
                                  staticClass: "dark-color",
                                  attrs: {
                                    "data-test":
                                      "Dashboard:InvestorDashboard:Status" +
                                      item.status,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "" + _vm.parseStatus(item.status)
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("td", { staticClass: "dark-color" }, [
                                item.statusDescription
                                  ? _c("div", [
                                      _c(
                                        "p",
                                        {
                                          class:
                                            "status-description " +
                                            (item.statusDescription.length >
                                              120 &&
                                              "status-description-max-size"),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.statusDescription) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      item.statusDescription.length > 120
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "read-more-status-description",
                                              on: {
                                                click: function () {
                                                  _vm.dialogFullStatusDescription =
                                                    {
                                                      show: true,
                                                      content:
                                                        item.statusDescription,
                                                    }
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("read_more"))
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _c("span", [_vm._v("-")]),
                              ]),
                              _c("td", [
                                _vm.getCommitmentTerm(item.files)
                                  ? _c(
                                      "div",
                                      { staticClass: "table-item-button" },
                                      [
                                        _c("DxaButtonToTable", {
                                          attrs: {
                                            title: _vm.$t("download"),
                                            loading:
                                              _vm.fileDownloading ==
                                              _vm.getCommitmentTerm(item.files)
                                                .path,
                                          },
                                          on: {
                                            btnFunction: function ($event) {
                                              _vm.get_doc_path(
                                                _vm.getCommitmentTerm(
                                                  item.files
                                                )
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("span", { staticClass: "dark-color" }, [
                                      _vm._v("-"),
                                    ]),
                              ]),
                              _vm.click_sign_feature
                                ? _c("td", [
                                    _vm.showFormalizationBtn(item.status)
                                      ? _c(
                                          "div",
                                          { staticClass: "table-item-button" },
                                          [
                                            _c("DxaButtonToTable", {
                                              attrs: {
                                                title:
                                                  _vm.defineStatusButtonSignContracts(
                                                    item
                                                  ),
                                                "data-test":
                                                  "Dashboard:Investor:Dashboard:Formalization",
                                              },
                                              on: {
                                                btnFunction: function ($event) {
                                                  return _vm.$router.push(
                                                    "/requests/sign-contracts/" +
                                                      item.id
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "dark-color",
                                            attrs: {
                                              "data-test":
                                                "Dashboard:InvestorDashboard:NoFormalization",
                                            },
                                          },
                                          [_vm._v("-")]
                                        ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            VDialog,
            {
              attrs: { "max-width": "564" },
              model: {
                value: _vm.dialogFullStatusDescription.show,
                callback: function ($$v) {
                  _vm.$set(_vm.dialogFullStatusDescription, "show", $$v)
                },
                expression: "dialogFullStatusDescription.show",
              },
            },
            [
              _c(
                VCard,
                { class: _vm.gs.isMobile() ? "" : "dxa_modal" },
                [
                  _c(VCardTitle, { staticClass: "mb-2" }, [
                    _c("h4", { staticClass: "dxa_modal_title h4" }, [
                      _vm._v(_vm._s(_vm.$t("status_description"))),
                    ]),
                  ]),
                  _c(
                    VCardText,
                    [
                      _c("p", { staticClass: "status-description-full-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.dialogFullStatusDescription.content) +
                            " "
                        ),
                      ]),
                      _c(
                        VCardActions,
                        { staticClass: "pl-0 dxa_modal_actions" },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "dxa_modal_btnError",
                              attrs: { color: "secondary", "min-width": "100" },
                              on: {
                                click: function () {
                                  _vm.dialogFullStatusDescription = {
                                    show: false,
                                    content: null,
                                  }
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }